var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{staticClass:"mb-3"},[_c('VRow',{attrs:{"dense":""}},_vm._l((_vm.picker),function(ref){
var code = ref.code;
var text = ref.text;
return _c('VCol',{key:code,attrs:{"cols":"auto"}},[_c('VCheckbox',{staticClass:"my-0 mr-3",attrs:{"label":text,"value":code,"hide-details":""},model:{value:(_vm.picked),callback:function ($$v) {_vm.picked=$$v},expression:"picked"}})],1)}),1)],1),_c('VDataTable',{attrs:{"headers":_vm.headers,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count || _vm.items.length,"mobile-breakpoint":0,"item-class":_vm.rowClassControl},on:{"update:options":function($event){_vm.options=$event},"update:page":function (page) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {page: page}) }); },"update:items-per-page":function (size) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {size: size}) }); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.sup),function(ref){
      var text = ref.text;
      var colspan = ref.colspan;
return _c('th',{key:text,staticClass:"text-start",attrs:{"colspan":colspan}},[_vm._v(_vm._s(text))])}),0)])]},proxy:true},{key:"body.prepend",fn:function(){return [_c('tr',_vm._l((_vm.headers),function(item){return _c('td',{key:item.value},[(item.filterable)?[(/[s\u017F]egment/i.test(item.filterKey))?_c('VSelect',{attrs:{"items":[{text: 'Да', value: 'Y'}, {text: 'Нет', value: 'N'}],"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[item.filterKey]},on:{"change":function (value) {
            var _obj;

            return _vm.$router.push({ query: _vm.pickBy(Object.assign({}, _vm.$route.query, ( _obj = {}, _obj[item.filterKey] = value, _obj )), Boolean) });
      }}}):_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[item.filterKey]},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: _vm.pickBy(Object.assign({}, _vm.$route.query, ( _obj = {}, _obj[item.filterKey] = value, _obj )), Boolean) });
      }}})]:_vm._e()],2)}),0)]},proxy:true},{key:"item.reviews",fn:function(ref){
      var userId = ref.item.general.items.userId;
return [_c('VBtn',{attrs:{"depressed":"","small":"","color":"light"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickReviewsShow(userId.value)}}},[_vm._v("Отзывы")])]}}])}),_c('VDialog',{attrs:{"max-width":"900","scrollable":"","persistent":""},model:{value:(_vm.reviewsDialogShow),callback:function ($$v) {_vm.reviewsDialogShow=$$v},expression:"reviewsDialogShow"}},[_c('VCard',{attrs:{"tile":""}},[_c('VToolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('VToolbarTitle',{staticClass:"px-2"},[_vm._v("Отзывы о контрагенте")]),_c('VSpacer'),_c('VBtn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.reviewsDialogShow = false}}},[_c('VIcon',[_vm._v("mdi-close")])],1)],1),_c('VCardText',{staticClass:"pt-5"},[(!_vm.userReviews.items.length)?_c('div',[_vm._v("Отзывы отсутствуют")]):_vm._e(),_vm._l((_vm.userReviews.items),function(comment){return _c('Comment',_vm._b({key:comment.id,attrs:{"disabled":_vm.pending}},'Comment',comment,false))}),_c('CommentForm',{staticClass:"pt-5",attrs:{"disabled":_vm.pending,"label":"Отзыв"},on:{"submit":_vm.onUserReviewSubmit}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }